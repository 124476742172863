.tippy-tooltip ul {
  margin: 0;
}

.tippy-tooltip {
  box-shadow: 0 0 20px rgba(50, 50, 93, 0.45);
}

.tippy-content {
  font-size: 1.5rem;
  text-align: left;
}

.tippy-tooltip.mergeling-pretty-theme {
  background-image: linear-gradient(
    to right bottom,
    #ddbaff,
    #d5b2ff,
    #ccabfe,
    #c2a3fe,
    #b89cfe
  );
}

.tippy-tooltip.mergeling-pretty-theme[data-placement^="top"] > .tippy-arrow {
  border-top-color: #ddbaff;
}

.tippy-tooltip.mergeling-pretty-theme[data-placement^="bottom"] > .tippy-arrow {
  border-bottom-color: #ddbaff;
}

.tippy-tooltip.mergeling-pretty-theme[data-placement^="left"] > .tippy-arrow {
  border-left-color: #ddbaff;
}

.tippy-tooltip.mergeling-pretty-theme[data-placement^="right"] > .tippy-arrow {
  border-right-color: #ddbaff;
}

.tippy-tooltip.mergeling-theme {
  box-shadow: 0 5px 35px rgba(60, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  background-image: linear-gradient(
    to right bottom,
    #05a8ff,
    #08a2f3,
    #0d9ce7,
    #1395db,
    #188fcf
  );
  color: white;
}

.tippy-tooltip.mergeling-theme h1 {
  color: white;
}

.tippy-tooltip.none-theme {
  background: transparent;
}

.tippy-tooltip.none-theme .tippy-content {
  padding: 0;
}
