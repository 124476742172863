.rc-input-number-handler {
  text-align: center;
  line-height: 1.6rem;
  height: 1.9rem;
  overflow: hidden;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}

.rc-input-number-handler-up-inner,
.rc-input-number-handler-down-inner {
  color: #b1b8e19e;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.rc-input-number-input-wrap {
  overflow: hidden;
}

.rc-input-number-input {
  -moz-appearance: textfield !important;
  height: 3.8rem;
}

.rc-input-number-handler-wrap {
  display: flex;
  float: right;
  /* border: 0.1rem solid #d1d1d1;
  border-radius: 0.4rem;
  width: 20px; */
  height: 3.8rem;
  transition: all 0.3s;
  margin-left: 3px;
}

.rc-input-number-handler {
  cursor: pointer;
  height: 3.8rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border: 1px solid #0f101988;
}

.rc-input-number-handler-up {
  /* border-bottom: 0.1rem solid #d1d1d1; */
  padding-top: 1px;
  transition: all 0.3s;
  margin-right: 0.5rem;
}

.rc-input-number-handler-up-inner::after {
  content: "+";
}

.rc-input-number-handler-down {
  transition: all 0.3s;
}

.rc-input-number-handler-down-inner::after {
  content: "−";
}

.rc-input-number-handler-down-disabled,
.rc-input-number-handler-up-disabled {
  opacity: 0.72;
}
