:root {
  --tab-color: #282b47;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  display: none;
  text-align: left;
  padding: 0;
  margin: 0;
}

.react-tabs__tab-list-left {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.react-tabs-proxy .react-tabs__tab-list {
  display: block;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}

.react-tabs__tab {
  max-width: 15rem;
  display: block;
  position: relative;
  padding: 8px 22px 8px 12px;
  cursor: pointer;
  color: #7c7ea2;
  margin-right: 1rem;
  margin-left: 1rem;
  user-select: none;
  border-bottom: 3px solid transparent;
  margin-bottom: 0.5rem;
  text-overflow: "..";
  white-space: nowrap;
  overflow: hidden;
}

.react-tabs__tab-close {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23999' viewBox='0 0 24 24'%3E%3Cpath d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z'/%3E%3C/svg%3E")
    no-repeat 50% 50%;
  display: block;
  background-size: 8px 8px;
  height: 8px;
  width: 8px;
  position: absolute;
  right: 0.4rem;
  top: 35%;
  padding: 6px;
}

.react-tabs__tab-close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:not(.react-tabs__tab--selected):hover {
  background-color: #333553;
  transition: 0.4s background-color;
}

.react-tabs__tab--selected {
  color: white;
  border-bottom: 3px solid #844dca;
}

/* .react-tabs__tab--selected::before {
  left: -100%;
  box-shadow: 14px 0.22em 0 -4px var(--tab-color);
  border-radius: 0 0 10px 0;

  content: " ";
  pointer-events: none;
  position: absolute;
  display: block;
  height: 80%;
  width: 100%;
  bottom: -1px;
  z-index: -1;
}

.react-tabs__tab--selected::after {
  right: -100%;
  box-shadow: -14px 0.22em 0 -4px var(--tab-color);
  border-radius: 0 0 0 10px;

  content: " ";
  pointer-events: none;
  position: absolute;
  display: block;
  height: 80%;
  width: 100%;
  bottom: -1px;
  z-index: -1;
} */

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab-panel {
  display: none;
  background-color: #252a44;
  background-image: linear-gradient(
    to left top,
    #252a44,
    #292e4a,
    #2c3251,
    #303757,
    #343b5e
  );
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 0 0 2rem 0;
  border-radius: 1.2rem;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs-add {
  margin-left: auto;
  margin-bottom: 1rem;
}

.react-tabs-add-icon {
  display: block;
  background-size: 12px 12px;
  height: 12px;
  width: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='357' fill='white' height='357'%3E%3Cpath d='M357 204H204v153h-51V204H0v-51h153V0h51v153h153v51z'/%3E%3C/svg%3E");
}
