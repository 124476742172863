body.theme-dark {
  background-color: #161a30;
  color: #b1b8e1;
}

.theme-dark table td {
  border-right-color: #0f1019;
  border-bottom-color: #0f1019;
}

.theme-dark table th {
  border-bottom-color: #0f1019;
}

.theme-dark .close::before,
.theme-dark .close::after {
  background-color: #eee;
}

.theme-dark input,
.theme-dark select {
  background-color: #191a2a;
  border-color: #0f1019;
  color: white;
}

.theme-dark hr {
  border-color: #0f1019;
}

/* .theme-dark .rc-input-number-handler-wrap {
  border-color: #0f1019;
}

.theme-dark .rc-input-number-handler-up {
  border-bottom-color: #0f1019;
}

.theme-dark .rc-input-number-handler-up-inner,
.theme-dark .rc-input-number-handler-down-inner {
  color: #888;
} */

.theme-dark .card {
  box-shadow: 0 5px 10px #0005;
  border: 1px solid #8b89f0;
  background-image: linear-gradient(
    to left top,
    #8072e5,
    #857cea,
    #8a86ef,
    #8f8ff4,
    #9599f8
  );
}

.theme-dark .card-title {
  background-color: #191a2a;
}

.theme-dark .card:hover {
  background-color: #191a2a;
}

.theme-dark .card-content {
  color: black;
}

.theme-dark .card.target {
  border: 1px solid #72e59a;
  background-image: linear-gradient(
    to left top,
    #72e59a,
    #7bea9f,
    #84eea4,
    #8df3aa,
    #95f8af
  );
}

/* .theme-dark h4 {
  border-bottom: 4px solid #05a8ff;
  color: black;
} */

.theme-dark .react-dropdown-select {
  background-color: #191a2a;
  border-color: #0f1019;
}

.theme-dark .react-dropdown-select-dropdown {
  background: #191a2a;
  border-color: #0f1019;
}

.theme-dark .react-dropdown-select-item {
  border-bottom-color: #0f1019;
}

.theme-dark .react-dropdown-select-item-selected {
  border-bottom-color: #0f1019 !important;
}

.theme-dark h1,
.theme-dark h2,
.theme-dark h3,
.theme-dark h4,
.theme-dark h5,
.theme-dark h6 {
  color: #96aaff;
}
