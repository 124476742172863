body.theme-light {
  background-color: white;
}

.theme-light table td {
  border-right-color: #e1e1e1;
}

.theme-light .close::before,
.theme-light .close::after {
  background-color: #333;
}
