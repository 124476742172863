@import "tippy.css";
@import "./rc-input-number.css";
@import "./react-tabs.css";
@import "./theme.light.css";
@import "./theme.dark.css";

body {
  letter-spacing: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.container {
  padding: 0 1rem;
}

footer {
  margin-top: 2rem;
  padding: 2rem;
}

input,
select,
textarea {
  margin-bottom: 0;
}

fieldset {
  margin-bottom: 1.5rem;
}

fieldset .container {
  padding: 0;
}

fieldset .container .column {
  align-self: center;
}

fieldset .container .column-auto {
  width: auto;
}

table {
  width: auto;
  margin: 0 auto;
}

table th,
table td {
  text-align: center;
}

table td {
  border-right: 0.1rem solid transparent;
}

table td:last-child,
th:last-child {
  border-right: none;
  padding: 1.2rem 1.5rem;
}

table td:first-child,
th:first-child {
  padding: 1.2rem 1.5rem;
}

ul {
  list-style-type: disc;
}

.app {
  text-align: center;
  letter-spacing: 0;
}

.navigation {
  background-image: linear-gradient(
    to right bottom,
    #844dca,
    #8b53d1,
    #915ad8,
    #9860e0,
    #9f67e7
  );
  margin-bottom: 3rem;
  display: block;
  max-width: 100%;
  width: 100%;
  z-index: 1;
  text-align: left;
}

.navigation > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.navigation a {
  color: white;
}

.navigation a:hover {
  color: white;
}

.navigation .img {
  fill: #9b4dca;
  height: 4rem;
  position: relative;
  top: 0.6rem;
  margin-right: 1rem;
}

.navigation .navigation-link,
.navigation .navigation-title,
.navigation .title {
  padding: 0;
  text-decoration: none;
  color: white;
}

.navigation .navigation-link {
  color: white;
}

.navigation .navigation-link:hover {
  cursor: pointer;
}

.navigation .title {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  vertical-align: middle;
}

.navigation .navigation-item {
  margin-bottom: 0;
  margin-right: 2.5rem;
  position: relative;
}

.navigation .navigation-item:last-child {
  margin-right: 0;
}

.navigation .navigation-list {
  list-style: none;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}

.configuration {
  max-width: 50rem;
  margin: 0 auto;
}

.configuration fieldset {
  text-align: left;
}

.configuration ul {
  list-style: none;
  margin-top: 10px;
}

.configuration li {
  padding: 5px;
  margin: 0;
}

.padder {
  margin: 3rem 0;
}

.changelog {
  margin-top: 1rem;
  text-align: left;
  padding: 2rem 0;
}

.changelog h1 {
  font-size: 2rem;
}

.changelog h2 {
  color: #df8423;
  font-size: 2rem;
  margin-bottom: 0.2rem;
}

.changelog h3 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
}

.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  font-size: 1.4rem;
}

.button-pop {
  background-image: linear-gradient(
    to right bottom,
    #ff72fd,
    #ec72ff,
    #d872ff,
    #c472ff,
    #ae72ff
  );
  border: none;
  color: white;
}

.button-pop:hover,
.button-pop:focus {
  box-shadow: 0 0 15px #aa4bf255;
  outline: 0;
}

.button-pop-outline {
  background-color: transparent;
  color: #a250f4;
  border: 0.1rem solid #a250f4;
}

.button-pop-outline:hover,
.button-pop-outline:focus {
  background-color: transparent;
  box-shadow: 0 0 10px #aa4bf255;
  color: #a250f4;
  border: 0.1rem solid #a250f4;
  outline: 0;
}

.menu {
  background: #d2bfff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 1rem;
  color: black;
}

.menu ul {
  list-style: none;
}

.menu h4 {
  font-size: 1.8rem;
  text-transform: uppercase;
  color: black;
  margin-bottom: 0.4rem;
}

.menu a {
  font-size: 1.6rem;
  color: #5130a2;
}

.menu a:hover {
  color: black;
}

.share {
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-image: linear-gradient(
    to right bottom,
    #ddbaff,
    #d5b2ff,
    #ccabfe,
    #c2a3fe,
    #b89cfe
  );
  padding: 1rem;
}

.share h5 {
  text-align: center;
  margin-bottom: 1rem;
  color: black;
}

.share input {
  height: 2.8rem;
}

.share button {
  font-size: 1.4rem;
  height: 2.8rem;
  line-height: 2.1rem;
  padding: 0 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.close {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 24px;
  opacity: 0.3;
  transition: all 0.3s ease-out;
  cursor: pointer;
  vertical-align: middle;
  align-self: center;
}

button.close {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.close:hover {
  opacity: 1;
}

.close::before,
.close::after {
  position: absolute;
  left: 12px;
  content: " ";
  height: 23px;
  width: 2px;
  transition: all 0.3s ease-out;
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}

.close:hover::before {
  transform: rotate(135deg);
  background: #b33;
}

.close:hover::after {
  transform: rotate(45deg);
  background: #b33;
}

label {
  display: inline-block;
}

label,
legend {
  font-weight: inherit;
}

.help {
  width: 20px;
  height: 20px;
  align-self: center;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-left: 5px;
  background-repeat: no-repeat;

  /* Used:
    https://jakearchibald.github.io/svgomg/
    https://yoksel.github.io/url-encoder/
  */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 92 92'%3E%3Cpath style='fill:rgb(5,168,255)' d='M45.386.004C19.983.344-.333 21.215.005 46.619c.34 25.393 21.209 45.715 46.611 45.377 25.398-.342 45.718-21.213 45.38-46.615-.34-25.395-21.21-45.716-46.61-45.377zM45.25 74l-.254-.004c-3.912-.116-6.67-2.998-6.559-6.852.109-3.788 2.934-6.538 6.717-6.538l.227.004c4.021.119 6.748 2.972 6.635 6.937C51.904 71.346 49.123 74 45.25 74zm16.455-32.659c-.92 1.307-2.943 2.93-5.492 4.916l-2.807 1.938c-1.541 1.198-2.471 2.325-2.82 3.434-.275.873-.41 1.104-.434 2.88l-.004.451H39.43l.031-.907c.131-3.728.223-5.921 1.768-7.733 2.424-2.846 7.771-6.289 7.998-6.435.766-.577 1.412-1.234 1.893-1.936 1.125-1.551 1.623-2.772 1.623-3.972a7.74 7.74 0 0 0-1.471-4.576c-.939-1.323-2.723-1.993-5.303-1.993-2.559 0-4.311.812-5.359 2.478-1.078 1.713-1.623 3.512-1.623 5.35v.457H27.936l.02-.477c.285-6.769 2.701-11.643 7.178-14.487C37.947 18.918 41.447 18 45.531 18c5.346 0 9.859 1.299 13.412 3.861 3.6 2.596 5.426 6.484 5.426 11.556 0 2.837-.896 5.502-2.664 7.924z' fill='%23030104'/%3E%3C/svg%3E");
}

fieldset .help {
  /* margin-bottom: 1.5rem; */
  margin-left: 0;
}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -1.5rem;
}

.card {
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  min-width: 4rem;
  margin-top: 1.5rem;
}

.card-image {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.card-image img {
  max-width: 7rem;
  max-height: 9rem;
  word-break: break-word;
}

.card-title {
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  flex: 1;
}

.card-content {
  display: block;
  padding: 0.6rem;
}

/* h4 {
  padding: 0.5rem;
} */

.react-dropdown-select {
  /* margin-bottom: 1.5rem; */
  padding: 0.6rem 1rem !important;
  border-radius: 0.6rem !important;
}

.react-dropdown-select:hover {
  border-color: transparent !important;
}

.react-dropdown-select:focus,
.react-dropdown-select:focus-within {
  outline: 0;
  box-shadow: none !important;
  border-color: #9b4dca !important;
}

.react-dropdown-select-content {
  flex-wrap: nowrap !important;
}

.react-dropdown-select-content > div,
.react-dropdown-select-content > span {
  flex-shrink: 0;
}

input.react-dropdown-select-input {
  margin: 0;
  background-color: transparent;
  font-size: inherit;
  flex: 1;
}

.react-dropdown-select-item {
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #0f1019;
}

.react-dropdown-select-item:hover,
.react-dropdown-select-item:focus {
  background: rgba(0, 116, 217, 0.1);
  outline: none;
}

.react-dropdown-select-item.react-dropdown-select-item-selected {
  background: #0074d9;
  color: #fff;
}

.mergechain-icon {
  display: inline-block;
  margin-right: 0.5rem;
  width: 30px;
  text-align: center;
}

.mergechain-icon img {
  vertical-align: middle;
  /* width: 40px; */
  height: 28px;
}

.tooltip-list {
  padding: 1rem;
}

.tooltip-list ul {
  margin: 0;
  padding: 0;
}

.tooltip-list li {
  margin: 0;
  padding: 0;
}

.pretty {
  /* margin-bottom: 1.5rem; */
  margin-right: 0;
}

.pretty .state label {
  text-indent: 0 !important;
}

.rc-input-number {
  transition: 0.5s all;
}

.rc-input-number-disabled {
  opacity: 0.32;
  cursor: not-allowed;
}

.rc-input-number-disabled input {
  cursor: not-allowed;
}

input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea,
select {
  border-radius: 0.6rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-sweet-progress {
  max-width: 200px;
  margin: 0 auto 2rem auto;
}

.react-sweet-progress-symbol {
  color: inherit;
}

.react-sweet-progress-line-inner-status-active {
  background-color: rgb(5, 168, 255) !important;
}

.react-sweet-progress-line-inner.undefined {
  background-color: rgb(77, 202, 87) !important;
}

.react-sweet-progress-line-inner::before {
  animation: none;
}

table.key-value td {
  border: none;
  text-align: left;
  padding: 0.3rem 1rem;
}

table.key-value td:first-child {
  font-weight: bold;
  text-align: right;
  vertical-align: top;
}

.card-tooltip {
  padding: 0.8rem;
}

.card-tooltip h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  text-align: center;
}

.card-tooltip-size {
  margin: 0 auto;
  text-align: center;
}

.card-tooltip-more {
  text-align: center;
  border-top: 0.1rem solid #f4f5f666;
  margin-top: 1rem;
}

.card-tooltip-more span {
  color: white;
  cursor: pointer;
}

.button-small {
  font-size: 1.2rem;
  height: 2.8rem;
  line-height: 2.8rem;
  padding: 0 1.5rem;
}

.panel-title {
  padding: 1rem;
  background: #384063;
  margin-bottom: 2rem;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  font-size: 2rem;
}

.panel-title h4 {
  display: inline;
  position: relative;
}

.panel-title h4:hover::after {
  opacity: 0.7;
  position: absolute;
  content: " ";
  width: 20px;
  height: 20px;
  top: 6px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55.25 55.25' %3E%3Cpath d='M52.618 2.631c-3.51-3.508-9.219-3.508-12.729 0L3.827 38.693c-.017.017-.027.038-.042.056-.021.024-.039.05-.058.076a.972.972 0 0 0-.125.239c-.009.026-.022.049-.029.075l-.012.03-3.535 14.85a.991.991 0 0 0-.022.202c0 .013-.004.025-.004.038a.995.995 0 0 0 .095.403c.049.107.11.21.196.296a1.006 1.006 0 0 0 .938.266l14.85-3.535c.027-.006.051-.021.077-.03a.985.985 0 0 0 .3-.162c.024-.019.049-.033.072-.054.008-.008.018-.012.026-.02L52.617 15.36c3.51-3.51 3.51-9.22.001-12.729zm-1.414 1.414c2.488 2.489 2.7 6.397.65 9.137l-9.787-9.787c2.741-2.05 6.649-1.838 9.137.65zm-4.95 14.85l-9.9-9.9 1.414-1.414 9.9 9.9-1.414 1.414zM4.961 50.288a.999.999 0 0 0-1.414 0l-.757.757 2.554-10.728 4.422-.491-.569 5.122c-.004.038.01.073.01.11 0 .038-.014.072-.01.11.004.033.021.06.028.092a1.016 1.016 0 0 0 .245.473c.048.051.1.094.157.134.045.031.088.06.138.084.066.031.135.049.207.066.038.009.069.03.108.035a.982.982 0 0 0 .109.006h.004a.995.995 0 0 0 .109-.006l5.122-.569-.491 4.422-10.729 2.554.757-.757a1 1 0 0 0 0-1.414zm12.55-5.479L39.889 22.43a.999.999 0 1 0-1.414-1.414L16.097 43.395l-4.773.53.53-4.773 22.38-22.378a.999.999 0 1 0-1.414-1.414L10.44 37.738l-3.183.354L34.94 10.409l9.9 9.9-27.683 27.683.354-3.183zm31.571-28.742l-9.9-9.9 1.415-1.415 9.9 9.9-1.415 1.415z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.panel-title input {
  max-width: 50rem;
  text-align: center;
}

.panel-content {
  padding: 0 1rem;
}

.burger {
  display: block;
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: white;
}

.burger:hover {
  color: rgba(255, 255, 255, 0.6);
}

.burger > h2 {
  cursor: pointer;
  display: block;
  font-size: 0px;
  height: 3px;
  position: absolute;
  top: 10px;
  width: 24px;
  user-select: none;
  background: currentColor;
  margin: 0px;
  outline: rgb(255, 255, 255) none 0px;
  right: 0px;
  border-radius: 1px;
  color: inherit;
}

.burger > h2::after {
  content: '""';
  cursor: pointer;
  display: block;
  font-size: 0px;
  height: 3px;
  position: absolute;
  top: 9px;
  width: 24px;
  user-select: none;
  background: currentColor;
  outline: rgb(255, 255, 255) none 0px;
  border-radius: 1px;
}

.burger > h2::before {
  content: '""';
  cursor: pointer;
  display: block;
  font-size: 0px;
  height: 3px;
  position: absolute;
  top: -9px;
  width: 24px;
  user-select: none;
  background: currentColor;
  outline: rgb(255, 255, 255) none 0px;
  border-radius: 1px;
}

.form-grid {
  display: grid;
  grid-gap: 0.3rem 1rem; 
  align-items: center;
}

.form-grid1 {
  grid-template-columns: 1fr auto;
}
.form-grid1 .form-grid-1 {
  grid-column: 1 / span 2;
  grid-row: 1;
}
.form-grid1 .form-grid-2 {
  grid-column: 1;
  grid-row: 2;
  width: 100%;
}
.form-grid1 .form-grid-3 {
  grid-column: 2;
  grid-row: 2;
}

.form-grid2 .form-grid-1 {
  grid-column: 1;
  grid-row: 1;
}
.form-grid2 .form-grid-2 {
  grid-column: 2 / span 3;
  grid-row: 1;
}
.form-grid2 .form-grid-3 {
  grid-column: 1 ;
  grid-row: 2 ;
}
.form-grid2 .form-grid-4 {
  grid-column: 2 ;
  grid-row: 2 ;
}
.form-grid2 .form-grid-5 {
  grid-column: 3 ;
  grid-row: 2 ;
}
.form-grid2 .form-grid-6 {
  grid-column: 4 ;
  grid-row: 2 ;
}

.form-grid3 {
  grid-template-rows: auto;
  grid-template-areas: "head head"
  "item item item item";
}
.form-grid3 .form-grid-1 {
  grid-column: 1;
  grid-area: "head";
}
.form-grid3 .form-grid-2 {
  grid-column: 2 / span 3;
  grid-area: "head";
}
.form-grid3 .form-grid-3 {
  grid-column: 1 ;
  grid-area: "item";
}
.form-grid3 .form-grid-4 {
  grid-column: 2 ;
  grid-area: "item";
}
.form-grid3 .form-grid-5 {
  grid-column: 3 ;
  grid-area: "item";
}
.form-grid3 .form-grid-6 {
  grid-column: 4;
  grid-area: "item";
}
